//
//  Toggle
//

$brand-primary: #9b111e;
$brand-secondary: #414a4c;
$gray-3: #828282;
$gray-6: #f2f2f2;
$success-color: #1bc5bd;

$breakpoint-mobile: 767.98px;
$breakpoint-tablet: 991.98px;
//Login
.btn-ScheduleClosings {
  background: #0b5ed4;
  color: $white;
  border-color: #0b5ed4;
  i {
    color: $white;
  }
  &:hover,
  &:focus {
    color: $white;
    background: #0650b9;
  }
}

// Left Side Bar Menu
.brand {
  .brand-logo {
    img {
      max-width: 115px;
    }
  }
}

.svg-icon {
  &.white-icon {
    svg {
      g {
        [fill] {
          fill: $white !important;
        }
      }
    }
  }
}

.aside-menu {
  .menu-nav {
    .menu-item {
      margin-bottom: 10px;
      font-size: 15px;
      &:hover,
      &:focus,
      &:active {
        color: $white !important;
        .menu-icon.svg-icon svg g [fill] {
          fill: $brand-primary !important;
        }
        .gray-3 {
          color: $white !important;
        }
        .menu-link {
          background: rgba($brand-secondary, 0.2) !important;
        }
      }
      &.menu-item-active {
        .gray-3 {
          color: $white !important;
        }
        .menu-icon.svg-icon svg g [fill] {
          fill: $brand-primary !important;
        }
        .menu-link {
          background: rgba($brand-secondary, 0.2) !important;
        }
      }
      .menu-icon.svg-icon svg g [fill] {
        fill: $gray-3 !important;
      }
    }
  }
}

//Topbar
.quick-search {
  &.search-dropdown {
    background: $white;
    .quick-search-wrapper {
      background: $white;
      margin-top: 0 !important;
      padding: 1rem 1.5rem;
      border-radius: 0.42rem;
      -webkit-box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
      box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    }
  }
  .quick-search-form {
    .input-group {
      &.input-group-brand {
        background: $gray-6;
        padding: 0 1rem;
        border-radius: 0.42rem;
        height: calc(1.5em + 1.3rem + 2px);
      }
    }
  }
}

#kt_subheader {
  display: none;
}

// Brand Input
.form-control {
  &.form-control-solid-brand {
    background: $gray-6;
    border: 0;
    color: $black;
    &:focus {
      background: $gray-6;
    }
    ::placeholder {
      color: $gray-3;
    }
  }
}

// Buttons
.btn {
  &.btn-brand {
    background-color: $brand-primary;
    border-color: $brand-primary;
    color: $white;
    &.symbol-50 {
      width: 50px;
      height: 50px;
    }
    &:hover,
    &:hover:not(.btn-text):not(:disabled):not(.disabled),
    &:focus,
    &:focus:not(.btn-text):not(:disabled):not(.disabled) {
      background-color: #7e0813;
      border-color: #7e0813;
      color: $white;
    }
    .svg-icon {
      svg {
        g {
          [fill] {
            fill: $white !important;
          }
        }
      }
    }
    i {
      color: $white;
    }
  }
  &.btn-edit-bank {
    color: $white;
    .svg-icon {
      svg {
        g {
          [fill] {
            fill: $white !important;
          }
        }
      }
    }
    i {
      color: $white;
    }
  }
  &.btn-delete-bank {
    color: $white;
    .svg-icon {
      svg {
        g {
          [fill] {
            fill: $white !important;
          }
        }
      }
    }
    i {
      color: $white;
    }
  }
  &.btn-light-brand {
    background: rgba($brand-primary, 0.1);
    color: $brand-primary;
    &:hover,
    &:hover:not(.btn-text):not(:disabled):not(.disabled),
    &:focus,
    &:focus:not(.btn-text):not(:disabled):not(.disabled) {
      background-color: $brand-primary;
      border-color: $brand-primary;
      color: $white;
      .svg-icon {
        svg {
          g {
            [fill] {
              fill: $white !important;
            }
          }
        }
      }
      i {
        color: $brand-primary;
      }
    }
    .svg-icon {
      svg {
        g {
          [fill] {
            fill: $brand-primary !important;
          }
        }
      }
    }
    i {
      color: $white;
    }
  }
}

.gray-6 {
  .btn {
    &.btn-secondary {
      background-color: $gray-6;
      border-color: $gray-6;
      color: $black;
    }
  }
}

//SVG Icon
.svg-icon {
  &.svg-icon-brand {
    svg {
      g {
        [fill] {
          fill: $brand-primary !important;
        }
      }
    }
  }
  &.svg-icon-success {
    svg {
      g {
        [fill] {
          fill: $success-color !important;
        }
      }
    }
  }

  &.svg-icon-gray3 {
    svg {
      g {
        [fill] {
          fill: $gray-3 !important;
        }
      }
    }
  }
  &.svg-icon-secondary {
    svg {
      g {
        [fill] {
          fill: $brand-secondary !important;
        }
      }
    }
  }
  &.svg-icon-white {
    svg {
      g {
        [fill] {
          fill: $white !important;
        }
      }
    }
  }
}

//Genrol
.text-brand {
  color: $brand-primary !important;
}

.text-secondary {
  color: $brand-secondary !important;
}

.text-dark {
  color: $black !important;
}

.fs-12 {
  font-size: 12px !important;
  line-height: 24px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
  line-height: 24px !important;
}

.fs-18 {
  font-size: 18px !important;
  line-height: 24px !important;
}

.fs-24 {
  font-size: 24px !important;
  line-height: 24px !important;
}

.fs-30 {
  font-size: 30px !important;
  line-height: 24px !important;
}

.gray-3,
.text-gray-3 {
  color: $gray-3 !important;
}

.font-size-base-small {
  font-size: 11px !important;
  line-height: inherit;
}

.flex-truncate {
  flex: 1 0 auto;
}

.full-border {
  position: relative;
  &::after {
    position: absolute;
    border-bottom: 1px solid $gray-6;
    content: "";
    display: block;
    left: -30px;
    bottom: 0;
    width: calc(100% + 60px);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.modal-open {
  overflow: hidden !important;
}

.dot-color {
  color: #1bc5bd;
}

.verify-badge {
  top: 4px;
  right: -4px;
  border: 0;
  width: 20px;
  height: 20px;
  position: absolute;
}

// Card mobile
.card {
  &.dashboard-history {
    min-height: calc(100vh - 305px);
  }
  @media (max-width: $breakpoint-mobile) {
    border-radius: 0px;
  }
  &-header,
  &-body {
    @media (max-width: $breakpoint-mobile) {
      padding: 2rem 1.25rem !important;
    }
  }
}

// #kt_content{
//   .flex-column-fluid{
//     .container:first-child{
//       @media (max-width: $breakpoint-mobile) {
//         padding: 0 !important;
//       }
//     }
//   }
// }

// Right Modal
.modal {
  &.right {
    .modal-dialog {
      position: fixed;
      margin: auto;
      width: 100%;
      max-width: 400px;
      height: 100%;
      -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
      -o-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
      @media (max-width: $breakpoint-mobile) {
        max-width: 100%;
        top: 56px;
        height: calc(100% - 56px);
      }
      .modal-content {
        height: 100%;
        overflow-y: auto;
        border-radius: 0;
        .modal-body {
          padding: 15px 30px 30px;
        }
      }
    }
    &.fade {
      .modal-dialog {
        right: -400px;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
      }
      &.show {
        .modal-dialog {
          right: 0;
        }
      }
    }
  }
}

//Modal
.rounded-close {
  background: $gray-6;
  border-radius: 50%;
  padding: 3px !important;
}

.modal-dialog {
  @media (max-width: $breakpoint-mobile) {
    margin: 0.5rem 15px;
  }
}

//History Page
#history {
  .border-bottom {
    &:last-child {
      border: 0 !important;
    }
  }
  .recipt-btn {
    min-width: 205px;
  }
}

// Contacts and Messaging Page
.nav.nav-pills > .nav-link,
.nav.nav-pills .nav-link {
  color: $brand-secondary;
  .svg-icon {
    svg {
      g {
        [fill] {
          fill: $brand-secondary;
        }
      }
    }
  }
}

.nav.nav-pills {
  .show > .nav-link,
  .nav-link.active {
    background: rgba($brand-primary, 0.1);
    color: $brand-primary;
    .svg-icon {
      svg {
        g {
          [fill] {
            fill: $brand-primary;
          }
        }
      }
    }
    &:hover,
    &:focus {
      background: $brand-primary;
      color: $white;
      .svg-icon {
        svg {
          g {
            [fill] {
              fill: $white;
            }
          }
        }
      }
    }
  }
  @media (max-width: $breakpoint-mobile) {
    &.card-header-pills {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
    .nav-item {
      flex: 0 0 50%;
      margin-right: 0;
      &:last-child {
        .nav-link {
          border-radius: 0 0.42rem 0.42rem 0;
        }
      }
      .nav-link {
        justify-content: center;
        margin-right: 0 !important;
        background: rgba($brand-primary, 0.1);
        color: $brand-primary;
        border-radius: 0.42rem 0 0 0.42rem;
        &.mobile-nav {
          padding: 0.75rem 0.5rem;
        }
        .svg-icon {
          svg {
            g {
              [fill] {
                fill: $brand-primary;
              }
            }
          }
        }
      }
    }
    .show > .nav-link,
    .nav-link.active {
      background: rgba($brand-primary, 1);
      color: $white;
      .svg-icon {
        svg {
          g {
            [fill] {
              fill: $white;
            }
          }
        }
      }
    }
  }
}

.msg-time {
  min-width: 100px;
  text-align: right;
  @media (max-width: $breakpoint-mobile) {
    min-width: 60px;
  }
}

.flot-button {
  position: fixed;
  right: 50px;
  bottom: 50px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #1bc5bd;
  box-shadow: 0px 7px 16px rgba(27, 181, 78, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: $breakpoint-mobile) {
    right: 20px;
    bottom: 20px;
  }
}

// Notification Page
.notification-btn {
  min-width: 285px;
  @media (max-width: $breakpoint-mobile) {
    min-width: auto;
  }
}

.w-150 {
  width: 150px;
}

// Account Setting Page
.setting-menu {
  &.navi {
    &-active {
      .navi-item {
        .navi-link {
          &.active,
          &:hover,
          &:focus,
          &:active {
            background: rgba($brand-primary, 0.1);
            border-radius: 40px;
            .navi-text {
              color: $brand-primary !important;
            }
          }
        }
      }
    }
  }
}
.account-setting {
  .image-input {
    border-radius: 50%;
    [data-action="change"] {
      top: inherit;
      bottom: 0px;
      margin-bottom: 0px;
      right: 15px;
    }
  }
  .saved-details,
  .two-factor {
    border-radius: 0.5rem;
    padding: 1rem;
  }
  .btn-brand.symbol-50 {
    width: 50px;
    height: 50px;
    background: $brand-primary;
  }
}

//Hide Login Aside Banner In Mobile
@media (max-width: $breakpoint-mobile) {
  .login-aside {
    display: none !important;
  }
}

//Bank Info Signup Flow Page
.bank-icon-wrapper {
  height: 100px;
  width: 100px;
  background-color: #ffffff;
  border: 3px solid $success-color;
  border-radius: 50%;
  margin: 0 auto;
  i {
    line-height: 95px;
    text-align: center;
  }
}

//Wizard Icon Background Changes
.MuiStepIcon-root {
  color: #1bc5bd;
  height: 46px !important;
  width: 46px !important;
  border-radius: 12px !important;
  font-weight: 600;
  background-color: #f7f6fb;
  circle {
    display: none;
  }
}
.MuiStepper-root {
  padding: 24px 0px !important;
  @media (max-width: $breakpoint-mobile) {
    display: none !important;
  }
}
.MuiStepLabel-iconContainer {
  padding-right: 13px !important;
}
.MuiStepIcon-root.MuiStepIcon-active {
  height: 46px;
  width: 46px;
  border-radius: 12px !important;
  background-color: rgba(27, 197, 189, 0.2);
  circle {
    display: none;
  }
  .MuiStepIcon-text {
    fill: rgba(27, 197, 189, 1) !important;
  }
}
.MuiStepIcon-root.MuiStepIcon-completed {
  color: #1bc5bd !important;
  height: 46px;
  width: 46px;
  border-radius: 12px !important;
  background-color: rgba(27, 197, 189, 0.1);
  position: relative;
  circle {
    display: none;
  }
  path {
    display: none;
  }
}

.MuiStepLabel-label {
  h5 {
    color: #b5b5c3 !important;
  }
  p {
    color: #d6d6e0 !important;
  }
}
.MuiStepLabel-label.MuiStepLabel-completed {
  h5 {
    color: #b5b5c3 !important;
  }
  p {
    color: #d6d6e0 !important;
  }
}
.MuiStepLabel-label.MuiStepLabel-active {
  h5 {
    color: #000000 !important;
  }
  p {
    color: #b5b5c3 !important;
  }
}
.MuiStep-horizontal {
  padding-left: 0px !important;
  padding-right: 0px !important;
  &.MuiStep-completed {
    span.MuiStepLabel-iconContainer {
      svg {
        display: none;
      }
      &::after {
        content: "";
        color: #1bc5bd !important;
        height: 46px;
        width: 46px;
        border-radius: 12px !important;
        background: rgba(27, 197, 189, 0.1)
          url("/media/svg/icons/Navigation/Check-green.svg") no-repeat center;
        position: relative;
        display: block;
      }
    }
  }
}

//Verification Signup Flow Page
.otp-wrapper {
  .inputs input {
    width: 58px;
    height: 58px;
    border-radius: 8px;
    text-align: center;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}

//Profile Camera Button Signup Flow Page
.upload-user-profile {
  .round-image-border {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 148px;
    width: 148px;
    border-radius: 50%;
    border: 5px solid #e5eaee;
  }
  label.btn-camera {
    background-color: #f7f6fb;
    height: 44px !important;
    width: 44px !important;
  }
  .image-input [data-action="change"] {
    bottom: -15px !important;
    top: inherit;
    right: -15px;
  }
  .image-input {
    .image-input-wrapper {
      height: 119px;
      width: 119px;
    }
  }
}

//Modal Success Icon Design
.success-icon-wrapper {
  height: 80px;
  width: 80px;
  background-color: $success-color;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  border-radius: 50%;
}

//Modal Error Icon Design
.danger-icon-wrapper {
  height: 80px;
  width: 80px;
  background-color: $danger;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  border-radius: 50%;
}

//Modal Error Icon Design
.info-icon-wrapper {
  height: 80px;
  width: 80px;
  background-color: $warning;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  border-radius: 50%;
}

//Two Factor Icon Design
.lock-icon {
  height: 100px;
  width: 100px;
  background-color: rgba(155, 17, 30, 0.3%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

//Make Payment Custom Select Box
.m-p-custom-select {
  position: relative;
  select {
    appearance: none;
  }
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-radius: 4px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #464e5f;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}

//Save Card Design Make Payment
.selcect-card-wrap {
  .save-card-box {
    padding: 20px;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    cursor: pointer;
    display: block;
    @media (max-width: $breakpoint-mobile) {
      padding: 0.5rem;
    }
    &:focus-visible {
      border-color: #1bc5bd;
    }
    .symbol-label {
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    .plus-btn {
      div {
        background-color: #f2f2f2;
        border: 1px solid #e0e0e0;
        height: 50px;
        width: 50px;
        color: $brand-primary;
      }
    }
    .upload-cheque {
      padding: 0px;
      position: relative;
      .upload__image-wrapper {
        button {
          min-width: 100%;
          display: block;
          height: 161px;
          background-color: #f2f2f2;
          border: 0px;
          border-radius: 4px;
          @media (max-width: $breakpoint-mobile) {
            height: 102px;
            padding: 5px;
          }
        }
      }
      h5 {
        margin-bottom: 0px;
        font-size: 13px;
        font-weight: 500;
        text-align: center;
        background-color: $success-color;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        padding: 10px 0px;
        color: #ffffff;
        border-radius: 0px 0px 4px 4px;
        @media (max-width: $breakpoint-mobile) {
          padding: 10px 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 11px;
        }
      }
      .image-item {
        height: 86px;
        width: 218px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 20px;
        @media (max-width: $breakpoint-mobile) {
          height: 45px;
          width: 120px;
          top: 10px;
        }
      }
    }
  }
}

//Make Payment Button Group
.modal-button-wrapper {
  display: grid;
  grid-auto-flow: column;
}

//Transfer Balance
.transfer-balance-wrapper {
  .t-b-left-section {
    flex: 0 0 325px;
    width: 325px;
    max-width: 100%;
    .t-b-card-label {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .t-b-right-section {
    width: 100%;
    .border-dashed {
      border-bottom: 2px dashed #f2f2f2;
    }
    .balance-avatar {
      height: 40px;
      width: 60px;
      background-color: #e6e6e6;
      border-radius: 4px;
      position: relative;
      img {
        height: auto;
        width: 100%;
        text-align: center;
        padding: 5px;
      }
      i {
        color: rgba(0, 0, 0, 0.3%);
      }
    }
  }
}

//Transfer Balance Mobile
@media (max-width: $breakpoint-mobile) {
  .make-payment-wrapper {
    .gutter-b {
      margin-bottom: 0px !important;
      .card-body {
        padding: 0px !important;
        .card-title {
          display: none !important;
        }
      }
    }

    .transfer-balance-wrapper {
      & > .d-flex {
        display: block !important;
      }

      .t-b-left-section {
        width: 100% !important;
        flex: initial !important;
        &.pr-4 {
          padding: 50px 20px 15px 20px !important;
          border-bottom: 1px solid #f2f2f2 !important;
          margin-bottom: 30px !important;
        }
        h2,
        p {
          text-align: center;
        }
        .t-b-card-select {
          .tb-card-mobile {
            padding-bottom: 0px !important;
            margin-bottom: 0px !important;
            border-bottom: 0px !important;
          }
        }
      }
      .t-b-right-section {
        padding: 0px 20px !important;
        &.border-left {
          border-left: 0px !important;
        }
      }
    }
  }
}

//Dashboard
.history-wrapper.container {
  padding-left: 0;
  padding-right: 0;
  @media (max-width: $breakpoint-mobile) {
    padding: 25px 15px 0;
    .card {
      border-radius: 0.42rem;
    }
  }
}

.mp-0 {
  @media (max-width: $breakpoint-mobile) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.makeStyles-root-1 {
  width: 100% !important;
}

.g-2 {
  .col-6 {
    @media (max-width: $breakpoint-mobile) {
      padding-left: 7.5px;
      padding-right: 7.5px;
    }
  }
}

.radio-inline {
  @media (max-width: $breakpoint-mobile) {
    display: block;
    label {
      margin-bottom: 1rem !important;
    }
  }
}

.content {
  @media (max-width: $breakpoint-mobile) {
    padding-bottom: 0px;
  }
}

.make-payment-inner {
  min-height: calc(100vh - 373px);
}

.history-table {
  td {
    min-width: 100px;
    &:first-child,
    &:nth-child(4) {
      min-width: 170px;
    }
    &:nth-child(5) {
      min-width: 250px;
    }
    &:first-child {
      min-width: 250px;
    }
    &:last-child {
      min-width: 250px;
    }
    @extend .py-5;
  }
}

#kt_chat_aside {
  // .infinite-scroll-component {
  //   height: calc(100vh - 221px) !important;
  // }
  // }
  .ps__rail-x {
    display: none;
  }
  .chat-link {
    padding: 0.75rem;
    position: relative;
    &:hover,
    &.current {
      background: #ebeef2;
      border-radius: 4px;
    }
  }
}

.flexible-height-infinite-scroll {
  height: calc(100vh - 221px) !important;
}

.infinite-scroll-component.custom-infinite-scroll-height {
  height: calc(100vh - 252px) !important;
}

.search-scroll-height {
  height: calc(100vh - 221px) !important;
  overflow: auto;
}

.chat-wrapper {
  max-height: calc(100vh - 311px);
  height: calc(100vh - 311px);
  @media (max-width: $breakpoint-mobile) {
    max-height: calc(100vh - 239px);
    height: calc(100vh - 239px);
  }
}

#kt_chat_content {
  .card {
    .card-header {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
    }
  }
}

.message-card {
  .infinite-scroll-component {
    min-height: calc(100vh - 290px) !important;
    height: calc(100vh - 290px) !important;
    @media (max-width: $breakpoint-tablet) {
      min-height: calc(100vh - 255px) !important;
    }
    @media (max-width: $breakpoint-mobile) {
      min-height: calc(100vh - 240px) !important;
    }
  }
  .chat {
    & + .chat {
      padding-top: 0.5rem;
    }
    display: flex;
    align-items: flex-end;
    margin: -4px;
    &.is-you {
      .chat-bubble {
        &:not(:first-child) {
          .chat-msg {
            border-top-left-radius: 4px;
          }
        }
        &:last-child {
          .chat-msg {
            border-bottom-left-radius: 0;
          }
        }
      }
    }
    .symbol-40 {
      width: 40px;
      height: 40px;
    }
    > div {
      padding: 4px;
    }
    .chat-bubble {
      display: flex;
      align-items: center;
      padding: 0.125rem 0;
      .chat-msg {
        background-color: rgba(235, 238, 242, 0.7);
        border-radius: 8px;
        padding: 0.5rem 1rem;
      }
      .chat-msg-more {
        display: flex;
        align-items: center;
        margin: 0 0.75rem;
        opacity: 0;
        flex-shrink: 0;
        transition: all 0.3s;
        li {
          list-style: none;
          width: 24px;
        }
      }
    }
    :hover + .chat-meta {
      display: flex;
      align-items: center;
      margin: 0.25rem -0.375rem 0;
      padding: 0;
      li {
        list-style: none;
        position: relative;
        padding: 0 0.375rem;
        font-size: 12px;
        color: #8094ae;
        &:not(:first-child) {
          &:before {
            content: "";
            position: absolute;
            top: 50%;
            font-size: 11px;
            line-height: 10px;
            left: 0;
            transform: translate(-50%, -50%);
            opacity: 0.8;
          }
        }
      }
    }
    .chat-meta {
      display: none;
    }
    &.is-me {
      justify-content: flex-end;
      :hover + .chat-meta {
        justify-content: flex-end;
      }
      .chat-bubble {
        flex-direction: row-reverse;
        &:last-child {
          .chat-msg {
            border-bottom-right-radius: 0;
          }
        }
      }
      .chat-msg {
        background: $brand-primary;
        color: $white;
      }
    }
  }
  .chat-sap {
    overflow: hidden;
    text-align: center;
    padding: 1rem 0;
    .chat-sap-meta {
      position: relative;
      display: inline-block;
      padding: 0 0.75rem;
      color: #8094ae;
      font-size: 12px;
      line-height: 1.4;
      &:before,
      &:after {
        position: absolute;
        height: 1px;
        background: #dbdfea;
        content: "";
        width: 100vw;
        top: 50%;
      }
      &:before {
        right: 100%;
      }
      &:after {
        left: 100%;
      }
    }
  }
}
.chat-footer {
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  background-color: #fff;
  border-width: 2px;
  .chat-editor-form {
    padding: 0 0.5rem;
    flex-grow: 1;
    .form-control-wrap {
      position: relative;
      textarea {
        min-height: 36px;
        padding-top: 0.5rem;
        resize: none;
        height: auto;
        border: 0;
        padding: 0;
        display: block;
        width: 100%;
        color: #222;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 4px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }
  }
  .chat-editor-tools {
    display: flex;
    align-items: center;
    li {
      list-style: none;
    }
  }
}
//

@media (max-width: 767px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  table {
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
      td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50% !important;
        &::before {
          /* Now like a table header */
          position: absolute;
          /* Top/left values mimic padding */
          top: 6px;
          left: 6px;
          width: 45%;
          padding-right: 10px;
          white-space: pre-wrap;
          content: attr(data-label);
        }
        &.notresponsive {
          padding-left: 00.75rem !important;
        }
      }
    }
  }

  /*
	Label the data
	*/
  // td:nth-of-type(1):before {
  //   content: "First Name";
  // }
  // td:nth-of-type(2):before {
  //   content: "Last Name";
  // }
  // td:nth-of-type(3):before {
  //   content: "Job Title";
  // }
  // td:nth-of-type(4):before {
  //   content: "Favorite Color";
  // }
  // td:nth-of-type(5):before {
  //   content: "Wars of Trek?";
  // }
  // td:nth-of-type(6):before {
  //   content: "Secret Alias";
  // }
  // td:nth-of-type(7):before {
  //   content: "Date of Birth";
  // }
  // td:nth-of-type(8):before {
  //   content: "Dream Vacation City";
  // }
  // td:nth-of-type(9):before {
  //   content: "GPA";
  // }
  // td:nth-of-type(10):before {
  //   content: "Arbitrary Data";
  // }
}
/*Empty Page Css*/
.empty-page-wrapper {
  .symbol-250 {
    height: auto;
    width: 250px;
    img {
      height: 100%;
      width: 100%;
      max-width: 100%;
    }
  }
}

/*Pay with X-tream Css*/
.pay-with-btn {
  min-width: 180px;
  @media (max-width: $breakpoint-mobile) {
    min-width: 100%;
    button {
      width: 100%;
      margin-left: 0px !important;
      margin-top: 15px;
    }
  }
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

.upload-image {
  position: absolute;
  inset: 60px 47px 45px;
}

.contact-messaging-wrapper {
  height: 100%;
  .contact-m-inner {
    height: 100%;
  }
  .no-content-image {
    height: calc(100vh - 332px);
  }
}

.notification-wrapper {
  height: 100%;
  .notification-m-inner {
    height: 100%;
    .card-header {
      .card-header-pills {
        margin-right: -12.5px;
        margin-left: -12.5px;
        &.nav.nav-pills .show > .nav-link,
        &.nav.nav-pills .nav-link.active {
          border-radius: 0.42rem 0.42rem 0 0;
        }
      }
    }
    .card-body {
      padding: 0;
      #right-tabs-example-tabpane-first,
      #right-tabs-example-tabpane-second {
        max-height: calc(100vh - 214px);
        overflow: auto;
        padding: 30px;
        .no-content-image {
          height: calc(100vh - 274px);
        }
      }
    }
  }
}

.no-content-image {
  height: calc(100vh - 115px);
}

.no-chat-found {
  height: calc(100vh - 220px);
}

.input-euro {
  position: relative;
}
.input-euro.left input {
  padding-left: 18px;
}
.input-euro.right input {
  padding-right: 18px;
  text-align: end;
}

.input-euro:before {
  position: absolute;
  top: 0;
  vertical-align: middle;
  content: "$";
  font-weight: 700;
  font-size: medium;
}
.input-euro.left:before {
  left: 15px;
  top: 17px;
}
.input-euro.right:before {
  right: 5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.status-color {
  padding: 5px 8px;
  height: 24px;
  line-height: 30px;
  border-radius: 5px;
  max-width: 150px;
  min-width: 150px;
}

.status-success {
  background-color: #c8f3f1;
  color: #1bc5bd;
}

.status-rejected {
  background-color: #f3ccd0;
  color: #ee2d41;
}

.status-pending {
  background-color: #cbf5c9;
  color: #11ad0b;
}

.status-in-progress {
  background-color: #fff4de;
  color: #ffa800;
}

.status-refund {
  background-color: #e1f0ff;
  color: #3699ff;
}

.status-failed {
  background-color: #e4e6ef;
  color: #997777;
}

.status-onhold {
  background-color: #ecd6ee;
  color: #bd0da0;
}

.no-resize {
  resize: none;
}

#kt_header_mobile {
  .notification-counter {
    @media (max-width: $breakpoint-tablet) {
      display: none;
    }
  }
}

// PDF Viewer CSS Start
.Example input,
.Example button {
  font: inherit;
}

.Example header {
  background-color: #323639;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  padding: 20px;
  color: white;
}

.Example header h1 {
  font-size: inherit;
  margin: 0;
}

.Example__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
}

.Example__container__load {
  margin-top: 1em;
  color: white;
}

.Example__container__document {
  margin: 1em 0;
}

.Example__container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Example__container__document .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;
}

.Example__container__document .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.Example__container__document .react-pdf__message {
  padding: 20px;
  color: white;
}

// PDF Viewer CSS End

.symbol-50 {
  width: 50px;
  height: 50px;
}

.react-custom-select-container {
  position: relative;
  .react-select__control {
    position: absolute;
    width: calc(100% - 24px);
    top: 12px;
    left: 12px;
    z-index: 2;
    &.react-select__indicators {
      padding: 0 10px;
    }
  }
  .react-select__menu {
    padding-top: 55px;
    margin-top: 0;
    border-radius: 0 0 2px 2px;
    // color: $font-color;
    .react-select__option {
      padding: 10px 12px;
      border-bottom: 1px solid $gray-3;
      cursor: pointer;
      &--is-focused {
        background: #f6f6f6;
      }
      &--is-selected {
        background: $brand-primary;
      }
    }
  }
}

.react-select-container {
  &.add-products {
    .react-select__control {
      min-height: 54px;
      width: 100%;
      border: 0;
      align-items: flex-start;
      .react-select__value-container {
        padding: 2px 8px;
      }
      .react-select__indicators {
        display: none;
      }
    }
  }
  &.receive-product-select {
    min-width: 100%;
  }
  .react-select__control {
    min-height: calc(#{$input-height} + 2px);
    border-color: $input-border-color !important;
    // color: $input-font-color;
    font-size: $input-font-size !important;
    border-radius: 2px;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    .react-select__value-container {
      padding: 2px 20px;
      .react-select__placeholder {
        color: $gray-3;
        font-size: $input-font-size;
      }
    }
  }
  .react-select__menu {
    margin-top: 0;
    border-radius: 0 0 2px 2px;
    // color: $font-color;
    .react-select__option {
      padding: 10px 12px;
      border-bottom: 1px solid $gray-3;
      cursor: pointer;
      &--is-focused {
        background: #f6f6f6;
      }
      &--is-selected {
        background: $brand-primary;
      }
    }
  }
}

.login-footer {
  box-shadow: 10px 2px 4px #000000;
  position: relative;
  z-index: 2;
}

.login.login-1.login-signin-on .login-signin {
  max-width: 550px !important;
}

.login.login-1 .login-form {
  max-width: 530px !important;
}

.sign-account {
  display: block;
  margin-top: 10px;
}

.citydrop-down {
  button.form-control {
    @extend .form-control;
    padding: 2rem 1rem;
    color: #3F4254 !important;
    &.form-control-lg {
      @extend .form-control-lg;
    }
    // .css-bula3r-ButtonBase {
    //   margin: 0px -13px;
    //   width: 16px;
    // }
    // .css-7no60z-ButtonBase {
    //   margin: 0px 10px !important;
    // }
    span {
        margin: 0px 0px;
        width: 16px;
      }
    span+span {
        margin: 0px -13px;
      }
  }
  
}

// .css-1ujqpe8 {
//   margin: 0px -20px;
//   width: 16px;
// }

// .css-19r5em7 {
//   margin: 0px 10px;
// }

.react-custom-select-container .react-select__menu .react-select__option {
  border-bottom: 0 !important;
}

.react-custom-select-container .react-select__menu .react-select__option--is-focused{
  background-color: #0b5ed4;
  color: #fff;
}

.city-padding-none {
  .react-select__menu {
    padding-top: 0 !important;
  }
}

.dropzone {
  background: white;
  border-radius: 5px;
  border: 2px dashed rgb(0, 135, 247);
  border-image: none;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.dropzone i{
  font-size: 25px;
}